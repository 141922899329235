import { fetchLaunchDarklyToggle, LaunchDarklyValue } from '../launchDarklyGateway';
import { useQuery } from '@tanstack/react-query';

export function useLaunchDarklyToggle(toggle: string): { isLoading: boolean; value: LaunchDarklyValue } {
  const { data, isError, isLoading } = useQuery({
    queryKey: [toggle],
    queryFn: () => fetchLaunchDarklyToggle(toggle),
    staleTime: 300000,
    gcTime: 300000,
  });

  return { isLoading, value: !isError && (data?.value || false) };
}
