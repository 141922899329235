import axios from 'axios';
import { apiUrl } from './common/gateway';

export type LaunchDarklyValue = boolean | string;

export interface LaunchDarklyToggle {
  value: LaunchDarklyValue;
}

export const fetchLaunchDarklyToggle = async (toggleName: string): Promise<LaunchDarklyToggle> => {
  return (await axios.get(apiUrl(`launch-darkly-toggles/${toggleName}`, 'v1')))?.data;
};
