import React, { ReactElement } from 'react';
import './common/gearboxComponents';
import FooterNotGearbox from './components/footer/Footer';
import Header from './components/header/Header';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { Routes } from './Routes';
import { Footer } from '@mo/gearbox';
import { useLaunchDarklyToggle } from './hooks/useLaunchDarklyToggle';

const AppContainer: React.FunctionComponent = (): ReactElement | null => {
  const { value: reBrandingToggle, isLoading: reBrandingToggleLoading } = useLaunchDarklyToggle('DC-26064-new-logo');
  return (
    <>
      <Header />
      <main>
        <ScrollToTop />
        <Routes />
      </main>
      {!reBrandingToggleLoading && reBrandingToggle ? <Footer className={'mt--auto'} /> : <FooterNotGearbox />}
    </>
  );
};

export default AppContainer;
